export default () => [
  // up to 50% off sale
  {
    from: new Date('7 August 2024 04:00:00 UTC'),
    content: {
      text: 'Up to 50% off sale! To shop, please',
      link: '/list/shop-all-tu-clothing-sale?tag=tu:propbar',
      colour: 'red',
    },
  },
  // 20% off selected kids
  {
    from: new Date('20 August 2024 08:00:00 UTC'),
    content: {
      text: '20% off selected kids. Ends 23:59 on 26th August. To shop, please',
      link: '/list/shop-kids-basics/?INITD=propbar',
      colour: 'black',
    },
  },
  // base propbar
  {
    from: new Date('26 August 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
  // 15% off school uniform
  {
    from: new Date('27 August 2024 08:00:00 UTC'),
    content: {
      text: '15% off school uniform! Ends 23:59 on 30th August. To shop, please',
      link: '/list/shop-all-school-uniform?tag=tu:propbar',
      colour: 'black',
    },
  },
  // base propbar
  {
    from: new Date('30 August 2024 23:59:00 UTC'),
    content: {
      text: '',
      linkText: 'Klarna available at checkout',
      link: '/help/payments-and-ordering',
      colour: 'black',
      icon: 'klarna',
    },
  },
]
